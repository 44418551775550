<template>
    <div>
        <h2 class="text-center success--text">
            Peace House <br />
            Agricultural Training Institute
        </h2>

        <v-divider class="my-2 mx-6"></v-divider>
        <div class="pl-3 justify-text">
            <!-- <v-card-title
            v-if="!about && showAdmissionAdvert"
            class="success--text"
            >Admission for {{ admissionYear }} Session!</v-card-title
            > -->
            <v-card-subtitle
            >
            <p>The Peace House Agricultural Training Institute (PHATI) is a vision of raising disciples, who are skilled and effective in discharging the purpose of God for their lives. It is an institute with a unique burden, standing on a tripartite stand in the pursuit of her mission which is as follows:</p>
            <ol>
                <li>To provide agricultural skill acquisition with life and character training for its trainees.</li>
                <li>To provide agricultural extension services for communities.</li>
                <li>Scientific researches into improvement of species of specified cash crops, animals and animal nutrition; and</li>
                <li>Empowerment of students graduating from the institute.</li>
            </ol><br>
<p>It is a full boarding training institute, to empower youths who are interested in agricultural vocation in order to actualize the purpose of God for their lives. Furthermore, improved farming methods, improved seedlings, and other discoveries from the scientific researches in the institute laboratory will be fully explored.</p>
<h3 class="text-center success--text">
            Focus of the Institution
        </h3>
        <ul>
            <li><b class="error--text">Hearticulture</b> (the culturing of the heart)</li>
            <li><b class="success--text">Agriculture</b></li>
        </ul>
        <p>&nbsp;</p>
            <p class="mb-0 error--text" v-if="showAdmissionClose">
                <b
                > Online Application for {{ applicationYear }} admission has closed.</b
            ></p>
           <p class="mb-0 error--text" v-if="showAdmissionAdvert">
                <b
                > Online Application for the {{ admissionYear }} session commences on July 15 till Sept 15, {{ applicationYear }}. </b
            ></p>
            </v-card-subtitle
            >
            <v-card-text class=""
            ><span class="success--text">Admission Requirements:</span> 
            <ul>
                <li>Applicant must be born-again.</li>
                <li>Graduate of any discipline from University, Polytechnic, Monotechnic, College of Education, Institute of Higher Learning, Secondary School Certificate holder with at least five credits including Mathematics and English Language. </li>
                <li><b>Age range:</b> 18 - 35 years</li>
                <li><b>Duration of Training:</b> Two (2)
            full years - (24 calendar months at a stretch)</li>
            </ul>
                    </v-card-text
            >
            <v-card-text v-if="showAdmissionAdvert" class="mt-n7"
            ><span class="success--text">Online Application:</span><br>
            July 15 - Sept 15, {{ applicationYear}} </v-card-text
            >
            <v-card-text class="mt-n7"
            >
            <v-btn v-if="showApplicationForm" color="success" class="mb-2" :to="{name:'Application'}">Apply Here</v-btn>
            </v-card-text>
        </div>
    </div>
</template>

<script>
import { apiClient } from "@/services";
    export default {
        name: "AboutDetails",
        props: {
            about: {
                type: Boolean,
                default: false 
            },
        },
        data() {
            return {
                showApplicationForm: false,
                showAdmissionClose: true,
                showAdmissionAdvert: true,
                applicationYear: 2025,
                admissionYear: 2026,
            }
    },
    async created(){
      try {
          const res = await apiClient.get( "/applicants_setup?_u=1" );
        if (res.data.status) {
            this.showApplicationForm = !!res.data.showApplicationForm;
            this.showAdmissionClose = !!res.data.showAdmissionClose;
            this.showAdmissionAdvert = !!res.data.showAdmissionAdvert;
            this.admissionYear = +res.data.admissionYear;
            this.applicationYear = +res.data.application_year;
        } 
      } catch (err) {
        console.log("Setup error");
      }
    },
    }

</script>