<template>
  <div>
    <h3 class="success mb-2 pa-2 white--text rounded">
      Section A: Personal Information
    </h3>
    <v-row>
      <v-col cols="12" sm="6" md="4"  v-if="!dform.id">
        <v-text-field
          v-model="dform.passwd"
          prepend-inner-icon="mdi-lock"
          label="Password (*)"
          outlined
          dense
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          v-model="dform.surname"
          label="Surname (*):"
          outlined
          dense
          :rules="[v => !!v || 'Item is required']"
          required
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          v-model="dform.other_names"
          label="Other Names (*):"
          outlined
          dense
          :rules="[v => !!v || 'Item is required']"
          required
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
         v-model.trim="dform.phoneno"
          type="number"
          maxlength="11"
          counter="11"
          label="Mobile (*):"
          outlined
          dense
          :rules="[v => !!v || 'Item is required']"
          required
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          v-model.trim="dform.email"
          label="E-mail Address (*):"
          outlined
          type="email"
          dense
          :rules="[v => !!v || 'Item is required']"
          required
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-radio-group
          v-model="dform.gender"
          dense
          row
          required
          :rules="[v => !!v || 'Item is required']"
        >
          <span class="mr-3">Gender (*): </span>
          <v-radio label="Male" value="M" color="red"></v-radio>
          <v-radio label="Female" value="F" color="red"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          v-model="dform.dob"
          type="date"
          label="Date of Birth (*):"
          :max="maxDate"
          outlined
          dense
          :rules="[v => !!v || 'Item is required']"
          required
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-select
          v-model="dform.marital_status"
          :items="maritals"
          label="Marital Status (*):"
          :color="color"
          required
          outlined
          :rules="[v => !!v || 'Item is required']"
        ></v-select>
      </v-col>      
      <v-col cols="12" sm="6" md="4">
        <v-select
          v-model="dform.rcountry"
          item-text="rcountry"
          item-value="rcountry"
          :items="countries"
          label="Country of Residence (*):"
          outlined
          dense
          required
          :rules="[v => !!v || 'Item is required']"
        >
        </v-select>
      </v-col>
       <v-col cols="12" sm="6" md="4">
        <v-select
          v-model="dform.rstate"
          label="State of Residence (*):"
          item-text="rstate"
          :items="cities"
          item-value="rstate"
          outlined
          dense
          required
          :rules="[v => !!v || 'Item is required']"
        >
        </v-select>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-select
          v-model="dform.ocountry"
          label="Country of Origin (*):"
          item-text="ocountry"
          item-value="ocountry"
          :items="countries"
          outlined
          dense
          required
          :rules="[v => !!v || 'Item is required']"
        >
        </v-select>
      </v-col>
     
      <v-col cols="12" sm="6" md="4">
        <v-select
          v-model="dform.ostate"
          label="State of Origin (*):"
          item-text="ostate"
          :items="cities"
          item-value="ostate"
          outlined
          dense
          required
          :rules="[v => !!v || 'Item is required']"
        >
        </v-select>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="dform.rtown"
          label="Town of Residence (*):"
          outlined
          dense
          required
          :rules="[v => !!v || 'Item is required']"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="dform.otown"
          label="Town of Origin (*):"
          outlined
          dense
          required
          :rules="[v => !!v || 'Item is required']"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <v-textarea
          v-model="dform.home_address"
          label="Contact Address (*):"
          outlined
          auto-grow
          row-height="15"
          required
          :rules="[v => !!v || 'Item is required']"
        >
        </v-textarea>
      </v-col>
      <v-col cols="12" sm="6">
        <v-textarea
          v-model="dform.health_challenges"
          label="Do you have any health chanllenge(s)? Mention (*):"
          outlined
          auto-grow
          row-height="15"
          :rules="[v => !!v || 'Item is required']"
          required
        >
        </v-textarea>
      </v-col>

      
    </v-row>
    <v-row justify="end">
      <v-btn
        v-if="dform.id"
        color="success"
        @click="saveChanges"
        class="ma-3"
        :loading="loading"
      >
        Save and Continue
      </v-btn>
      <v-btn
       v-else
        :disabled ="!isValid"
          color="success"
          @click="startApplication"
          class="ma-3"
          :loading="loading"
        >
          Start Application
        </v-btn>
    </v-row>
  </div>
</template>

<script>

import { apiClient } from "@/services";
import { mapState } from "vuex";
import { editUserMixin } from "@/mixins";

export default {
mixins: [editUserMixin],
  props: {
    alert: Function,
  },
  data() {
    return {
      loading: false,
      color: "success",
      showPassword: false,
      maritals: ["Single", "Engaged", "Married"],


      dform: {
        id: "",
        application_no: "",
        passwd: "",
        surname: "",
        other_names: "",
        phoneno: "",
        email: "",
        gender: "",
        dob: "",
        marital_status: "",
        home_address: "",
        rcountry: "",
        ocountry: "",
        rstate: "",
        ostate: "",
        rtown: "",
        otown: "",
        health_challenges: ""
      }
    };
  },
   computed: {
    ...mapState(["cities","countries"]),
    isValid (){
      return this.dform.passwd &&
        this.dform.surname  &&
        this.dform.other_names  &&
        this.dform.phoneno &&
        this.dform.email &&
        this.dform.gender&&
        this.dform.dob &&
        this.dform.marital_status &&
        this.dform.home_address &&
        this.dform.rcountry &&
        this.dform.ocountry &&
        this.dform.rstate &&
        this.dform.ostate &&
        this.dform.rtown &&
        this.dform.otown &&
        this.dform.health_challenges
    },
    // minDate(){
    //   // const now = new Date()
    //   let year = new Date().getFullYear()
    //  return "2005-01-01"  
    // },
    maxDate(){
      let year = new Date().getFullYear()
      return `${year - 17}-12-31`
    },
  },
  // created(){
  //   console.log('personal dform: ',this.dform);
  // },
  methods: {
    startApplication() {
      this.loading = true;
      // Do Validation here
      this.dform.application_type = this.$route.path == '/pin' ? 'intern' : 'normal';
      console.log(JSON.stringify(this.dform))
      apiClient
        .post("/applicants.php", this.dform)
        .then((res) => {
          // console.log(res.data);
          if (res.data.id) {
            let passwd = this.dform.passwd
            this.dform = res.data;
            this.dform.passwd =  passwd;
            this.loading = false;
            this.$emit("started-application", { user: this.dform});
          } else {
            this.alert(res.data, "error");
            this.loading = false;
          }
        })
        .catch((err) => {
          this.alert(err.message, "error");
          this.loading = false;
        });
    },
    saveChanges() {
      // this.dform.id = this.formId;
      this.dform.type = "personal";
      this.loading = true;
      // Do Validation here
      apiClient
        .put("/applicants.php", this.dform)
        .then((res) => {
          if (res.data.ok) {
            this.alert();
            this.$emit("updated-personal", { step: 2 });
          } else {
            this.alert(res.data, "error");
            this.loading = false;
          }
        })
        .catch((err) => {
          this.alert(err.message, "error");
          this.loading = false;
        });
    },
}
}
</script>


